import * as Sentry from '@sentry/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import RelativeTime from 'dayjs/plugin/relativeTime.js';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
import AdvancedFormat from 'dayjs/plugin/advancedFormat.js';
import { atomWithStorage } from 'jotai/utils';
import { PhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPaymentMethods, getUserReservation } from '../../../lib/api.ts';
import { ApiError } from '../../../lib/ApiError.ts';
import {
  AuthResponse,
  GetReservation,
  PaymentMethod,
} from '../../../lib/types.ts';
import { DIRECT_LINK_STATE_VERSION, DirectLinkState } from './types.ts';

dayjs.extend(isToday);
dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(AdvancedFormat);

export const NowAtom = atom<Dayjs>(dayjs());
export const AuthAtom = atomWithStorage<AuthResponse | null>('dl_auth', null);
export const PhoneAtom = atom<PhoneNumber | null>(null);
export const ReservationAtom = atom<GetReservation | null>(null);
export const LoadReservationAtom = atom<Dayjs>(dayjs());
export const LoadPaymentMethodsAtom = atom<Dayjs>(dayjs());
export const PaymentMethodsAtom = atom<PaymentMethod[]>([]);
export const PaymentMethodsByIdAtom = atom<Record<string, PaymentMethod>>({});

const getExpiration = (): Dayjs => dayjs().add(30, 'minute');

export const useInit = () => {
  const setNow = useSetAtom(NowAtom);

  useEffect(() => {
    const intervalId = setInterval(() => setNow(dayjs().utc()), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
};

export const DirectLinkStateAtom = atomWithStorage<DirectLinkState>(
  'dl_reservation',
  {
    version: DIRECT_LINK_STATE_VERSION,
    expires: getExpiration(),
    unauthorized_error: null,
    reservation_accepted: null,
    rules_accepted: null,
    policy_accepted: null,
    reservation_expired: null,
    opt_in_accepted: 1,
  }
);

export const updateDirectLinkStateAtom = atom(
  null,
  (get, set, update: Partial<DirectLinkState>) => {
    const currentState = get(DirectLinkStateAtom);
    set(DirectLinkStateAtom, {
      ...currentState,
      ...update,
      expires: getExpiration(),
    });
  }
);

export const resetDirectLinkStateAtom = atom(null, (_get, set) => {
  set(DirectLinkStateAtom, {
    version: DIRECT_LINK_STATE_VERSION,
    expires: getExpiration(),
    unauthorized_error: null,
    reservation_accepted: null,
    rules_accepted: null,
    policy_accepted: null,
    reservation_expired: null,
    opt_in_accepted: 1,
  });
});

export const reloadReservationAtom = atom(null, (_get, set) => {
  set(LoadReservationAtom, dayjs());
});

export const reloadPaymentMethodsAtom = atom(null, (_get, set) => {
  set(LoadPaymentMethodsAtom, dayjs());
});

export const useResetState = (): (() => void) => {
  const setPhone = useSetAtom(PhoneAtom);
  const resetReservation = useSetAtom(resetDirectLinkStateAtom);

  return () => {
    setPhone(null);
    resetReservation();
  };
};

export const useDirectLinkState = (): [
  DirectLinkState,
  (partial: Partial<DirectLinkState>) => void
] => {
  const directLink = useAtomValue(DirectLinkStateAtom);
  const setDirectLink = useSetAtom(updateDirectLinkStateAtom);

  const update = (partial: Partial<DirectLinkState>) => {
    setDirectLink(partial);
  };

  return [directLink, update];
};

export const useReservation = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [auth, setAuth] = useAtom(AuthAtom);
  const setPhone = useSetAtom(PhoneAtom);
  const [, setDirectLink] = useDirectLinkState();
  const reload = useAtomValue(LoadReservationAtom);
  const setReservation = useSetAtom(ReservationAtom);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    if (!auth?.token) {
      return;
    }

    const get = async () => {
      try {
        const response = await getUserReservation(auth.token, uuid);

        if (response) {
          const claim_deadline_at_utc = dayjs(
            `${response.reservation.claim_deadline_at}`
          ).utc();
          const reservation_at_tzd = dayjs(
            response.reservation.reservation_at
          ).tz(response.reservation.restaurant.timezone);
          setReservation({
            ...response.reservation,
            reservation_at_tzd,
            claim_deadline_at_utc,
          });

          setDirectLink({
            uuid,
            first_name: response.reservation.user.first_name,
            last_name: response.reservation.user.last_name,
            email: response.reservation.user.email,
          });

          if (response.reservation.confirmed_at) {
            navigate('./confirmation');
          } else if (
            claim_deadline_at_utc.diff(dayjs().utc(), 'second') < 0 ||
            response.reservation.cancelled ||
            response.reservation.sold_out
          ) {
            navigate('./expired');
          }
        }
      } catch (e: unknown) {
        if (e instanceof ApiError && e.type === 'UnauthorizedErrorResponse') {
          Sentry.setUser(null);
          setAuth(null);
          setPhone(null);
          setDirectLink({
            uuid,
            unauthorized_error: true,
          });

          navigate('./phone');
        } else if (
          e instanceof ApiError &&
          (e.type === 'ReservationUnavailableErrorResponse' ||
            e.type === 'ReservationResourceNotFound')
        ) {
          setDirectLink({
            reservation_expired: true,
          });
          console.error('Reservation Expired Error', e);
          Sentry.captureException(e);
          navigate('./expired');
        } else if (e instanceof ApiError) {
          console.error('Get Reservation Error', e);
          Sentry.captureException(e);
          navigate('./expired');
        } else {
          console.error('Unrecognized Error', e);
          Sentry.captureException(e);
          navigate('./expired');
        }
      }
    };

    void get();
  }, [auth?.token, uuid, reload]);
};

interface RemainingTimeResponse {
  show_countdown: boolean;
  remaining: string;
}

export const useRemainingTime = (): RemainingTimeResponse => {
  const reservation = useAtomValue(ReservationAtom);
  const now = useAtomValue(NowAtom);
  const [remaining, setRemaining] = useState<string>('');
  const [show_countdown, showCountdown] = useState<boolean>(false);

  useEffect(() => {
    if (!reservation?.claim_deadline_at) {
      return;
    }

    const diff = reservation.claim_deadline_at_utc.diff(now, 'minutes', true);
    const minutes = Math.floor(diff);
    if (minutes <= 60) {
      const seconds = Math.floor((diff - minutes) * 60);

      showCountdown(true);
      setRemaining(`${minutes}:${('' + seconds).padStart(2, '0')}`);
    } else {
      setRemaining(reservation.claim_deadline_at_utc.local().format('llll z'));
    }
  }, [now, reservation?.claim_deadline_at]);

  return {
    show_countdown,
    remaining,
  };
};

export const usePaymentMethods = () => {
  const auth = useAtomValue(AuthAtom);
  const reload = useAtomValue(LoadPaymentMethodsAtom);
  const setMethods = useSetAtom(PaymentMethodsAtom);
  const setMethodsById = useSetAtom(PaymentMethodsByIdAtom);

  useEffect(() => {
    if (!auth?.token) {
      return;
    }

    const go = async () => {
      try {
        const response = await getPaymentMethods(auth.token);

        if (!response?.data) {
          setMethods([]);
          setMethodsById({});
          return;
        }

        setMethods(response.data);

        const by_id = {};
        response.data.map((method) => (by_id[method.id] = method));
        setMethodsById(by_id);
      } catch (e: unknown) {
        Sentry.captureException(e);
      }
    };

    void go();
  }, [auth?.token, reload]);
};
